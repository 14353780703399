import { createSlice } from "@reduxjs/toolkit";
import { getOrders } from "./../../api/api";
import { getArchiveOrders } from "./../../api/api";
import { operatorComment } from '../../api/api'

const initialState = {
  orders: {orders:[]},
  flag: false,
  currentOrder: null,
  currentOffset: 0,
  loading: false,
  archiveLoading: false,
  error: "",
  operatorCommentModal: false,
  refreshGetOrder: null,
  orderRefreshItem: {},
};

export const ordersSlice = createSlice({
  name: "orders",
  initialState,
  reducers: {
    currentOrderAC: (state, action) => {
      state.currentOrder = action.payload;
    },
    ordersFlagAC: (state, action) => {
      state.flag = action.payload;
    },
    currentOffsetAC: (state, action) => {
      state.currentOffset = action.payload;
    },

    addRealObj: (state, action) => {
      let loopState = false;
      if(!state.orders.orders){
        state.orders.orders = [action.payload]
      }else{
        for (let i = 0; i <= state?.orders?.orders?.length; i++) {
          if (
            state?.orders?.orders[i]?.order_uuid === action.payload.order_uuid
            ) {
              state?.orders?.orders.splice(i, 1, action.payload);
              loopState = true;
              break;
            }
          }
        !loopState && state?.orders?.orders?.unshift(action.payload);
      }
    },

    setOperatorCommentModal: (state, action) => {
      state.operatorCommentModal = action.payload
    },
    setOrderRefreshItemId: (state, action) => {
      state.orderRefreshItem = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getOrders.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getOrders.fulfilled, (state, action) => {
      state.loading = false;
      state.orders = action.payload;
    });
    builder.addCase(getOrders.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    builder.addCase(getArchiveOrders.pending, (state) => {
      state.archiveLoading = true;
    });
    builder.addCase(getArchiveOrders.fulfilled, (state, action) => {
      state.archiveLoading = false;
      state.orders = action.payload;
    });
    builder.addCase(getArchiveOrders.rejected, (state, action) => {
      state.archiveLoading = false;
      state.error = action.payload;
    });

    
    builder.addCase(operatorComment.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(operatorComment.fulfilled, (state, action) => {
      state.loading = false;
      state.orderRefreshItem = action.payload;
      state.operatorCommentModal = false;
      state.refreshGetOrder = Math.random();
    });
    builder.addCase(operatorComment.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
  },
});

export const { currentOrderAC, ordersFlagAC, currentOffsetAC, addRealObj, setOperatorCommentModal, setRefreshGetOrder, setOrderRefreshItemId } =
  ordersSlice.actions;

export default ordersSlice.reducer;
