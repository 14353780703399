import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import { saveToken, destroyToken } from "../../utils/Token";
import { axiosBaseRequest, axiosBaseUrl } from "../../utils/axiosBaseUrl";

const initialState = {
  registerStatus: "",
  registerError: "",
  loginStatus: "",
  loginError: "",
  status: "",
  loading: false,
};

export const registerUser = createAsyncThunk(
  "users/addUsers",
  async ({ body, navigate }, { rejectWithValue }) => {
    try {
      let uri = `users/`;

      const { data } = await axiosBaseUrl.post(uri, body);

      localStorage.setItem("email", body.email);
      navigate("/verify");

      return data;
    } catch (error) {
      message.error(error.message);
      return rejectWithValue(error.message);
    }
  }
);

export const registerActivate = createAsyncThunk(
  "users/activateUser",
  async ({ body, navigate }, { rejectWithValue }) => {
    try {
      let uri = `users/activate`;

      const { data } = await axiosBaseUrl.post(uri, {
        username: body.username,
        pincode: body.pincode,
      });

      localStorage.removeItem("email");
      navigate("/");
      return data;
    } catch (error) {
      message.error(error.response.data.detail);
      return rejectWithValue(error.message);
    }
  }
);

export const forgotPassword = createAsyncThunk(
  "users/forgotPassword",
  async ({ body, navigate }, { rejectWithValue }) => {
    try {
      let uri = `forgot-password`;

      const { data } = await axiosBaseUrl.post(uri, body);
      navigate("/");
      return data;
    } catch (error) {
      message.error(error.response.data.detail);
      return rejectWithValue(error.message);
    }
  }
);

export const loginUser = createAsyncThunk(
  "login/loginUser",
  async ({ body, navigate }, { rejectWithValue }) => {
    try {
      let uri = `login/`;

      const { data } = await axiosBaseUrl.post(uri, body, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });

      saveToken(data.access_token, data.refresh_token);

      navigate("/home");

      return data;
    } catch (error) {
      message.error(error.response.data.detail);
      return rejectWithValue(error.message);
    }
  }
);

export const logout = createAsyncThunk(
  "users/logout",
  async (_, { rejectWithValue }) => {
    try {
      let uri = `logout`;

      const { data } = await axiosBaseUrl.post(uri);
      destroyToken();
      return data;
    } catch (error) {
      message.error(error.response.data.detail);
      return rejectWithValue(error.message);
    }
  }
);

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(registerUser.pending, (state, action) => {
      state.registerStatus = "pending";
      // return { ...state, registerStatus: "pending" };
    });
    builder.addCase(registerUser.fulfilled, (state, action) => {
      state.registerStatus = "success";
    });
    builder.addCase(registerUser.rejected, (state, action) => {
      state.registerStatus = "rejected";
      state.registerError = action.payload;
    });

    builder.addCase(loginUser.pending, (state, action) => {
      state.loginStatus = "pending";
      state.loading = true;
      // return { ...state, loginStatus: "pending" };
    });
    builder.addCase(loginUser.fulfilled, (state, action) => {
      state.loginStatus = "success";
      state.loading = false;
    });
    builder.addCase(loginUser.rejected, (state, action) => {
      state.loginStatus = "rejected";
      state.loginError = action.payload;
      state.loading = false;
    });

    builder.addCase(forgotPassword.pending, (state, action) => {
      state.status = "pending";
      // return { ...state, status: "pending" };
    });
    builder.addCase(forgotPassword.fulfilled, (state, action) => {
      state.status = "Success";
    });
    builder.addCase(forgotPassword.rejected, (state, action) => {
      state.status = "rejected";
      state.error = action.payload;
    });

    builder.addCase(logout.pending, (state, action) => {
      state.status = "pending";
    });
    builder.addCase(logout.fulfilled, (state, action) => {
      state.status = "Success";
    });
    builder.addCase(logout.rejected, (state, action) => {
      state.status = "rejected";
      state.error = action.payload;
    });
  },
});

export default authSlice.reducer;
