import { createAsyncThunk } from "@reduxjs/toolkit";
import { axiosOrdersRequest } from "../utils/axiosOrdersURL";
import { message, notification } from "antd";
import { ordersFlagAC } from "../store/slices/ordersSlice";

export const getArchiveOrders = createAsyncThunk(
  "api/getArchiveOrders",
  async ({ searchTxt, offset, status }, { rejectWithValue }) => {
    try {
      let uri = `order/archive/all`;
      if (offset !== undefined) {
        uri += `?offset=${offset}&limit=10`;
      }
      if (status !== null && status !== undefined) {
        uri += `&order_status=${status}`;
      }
      if (searchTxt !== undefined && searchTxt !== "") {
        uri += `?q=${searchTxt}`;
      }

      const { data } = await axiosOrdersRequest.get(uri);
      return data;
    } catch (error) {
      message.error(error.response.data.detail);
      return rejectWithValue(error.message);
    }
  }
);

export const getOrders = createAsyncThunk(
  "api/getOrders",
  async ({ searchTxt, offset, status }, { rejectWithValue }) => {
    try {
      let uri = `order/restricted/all`;
      if (offset !== undefined) {
        uri += `?offset=${offset}&limit=10`;
      }
      if (status !== null && status !== undefined) {
        uri += `&order_status=${status}`;
      }
      if (searchTxt !== undefined && searchTxt !== "") {
        uri += `?q=${searchTxt}`;
      }

      const { data } = await axiosOrdersRequest.get(uri);
      return data;
    } catch (error) {
      message.error(error.response.data.detail);
      return rejectWithValue(error.message);
    }
  }
);

export const orderStatusUpdate = createAsyncThunk(
  "api/orderStatusUpdate",
  async ({ orderId, orderStatus }, { rejectWithValue, dispatch }) => {
    try {
      let uri = `order/status?order_uuid=${orderId}&order_status=${orderStatus}`;
      const data = await axiosOrdersRequest.patch(uri);
      if (data) {
        dispatch(ordersFlagAC((flag) => !flag));
        notification["success"]({
          message: "Изменен!",
          description: "Статус заказа успешно изменен!",
        });
      }
    } catch (error) {
      message.error(error.response.data.detail);
      notification["error"]({
        message: "Error!",
        description: "Something went wrong!",
      });
      return rejectWithValue(error.message);
    }
  }
);


export const operatorComment = createAsyncThunk(
  "api/operatorComment",
  async ({ description, id }, { rejectWithValue, dispatch }) => {
    try {
      let uri = `/order/operator/comment/?order_uuid=${id}&operator_comment=${description}`;
      const {data} = await axiosOrdersRequest.patch(uri);
      dispatch(getOrders())
      return data
    } catch (error) {
      message.error(error.response.data.detail);
      return rejectWithValue(error.message);
    }
  }
);
