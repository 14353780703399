import React, { Suspense, lazy, useEffect } from 'react'
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom'
// import Layout from "./components/Layout";
// import Orders from "./components/Orders";
// import Archive from "./components/Archive";
// import NotFound from "./components/404/404";
// import Error from "./components/404/Error";
// import AuthLayout from "./components/auth/AuthLayout";
// import LoginLayout from "./components/auth/LoginLayout";
// import Registration from "./components/auth/Registration";
// import Verify from "./components/auth/Verify";
// import ForgotPassword from "./components/auth/ForgotPassword";
import "./App.css";
import { getToken } from './utils/Token';
import Loader from './components/loader/Loader';


const Layout = lazy(()=> import ('./components/Layout'));
const Orders = lazy(()=> import ('./components/Orders'));
const Archive = lazy(()=> import ('./components/Archive'));
const NotFound = lazy(()=> import ('./components/404/404'));
const Error = lazy(()=> import ('./components/404/Error'));
const AuthLayout = lazy(()=> import ('./components/auth/AuthLayout'));
const LoginLayout = lazy(()=> import ('./components/auth/LoginLayout'));
const Registration = lazy(()=> import ('./components/auth/Registration'));
const Verify = lazy(()=> import ('./components/auth/Verify'));
const ForgotPassword = lazy(()=> import ('./components/auth/ForgotPassword'));

function App() {
  const {pathname} = useLocation()
  const navigate = useNavigate()
  let token = getToken()
  
  useEffect(()=>{
    if(pathname.toLowerCase().includes('/home') && !token){
        navigate('/')
        console.log('00000000')
    }
    if(pathname == '/' && token){
        navigate('/home')
    }
  },[pathname, token])
  return (
    <div>
        <Routes>
            <Route path="/home" element={<Suspense fallback={<Loader/>}><Layout /></Suspense>} errorElement={<Suspense fallback={<Loader/>}><Error/></Suspense>}>
              <Route index element={<Suspense fallback={<Loader/>}><Orders /></Suspense>} />
              <Route path="archive" element={<Suspense fallback={<Loader/>}><Archive /></Suspense>} />
            </Route>
            <Route path="*" element={<Suspense fallback={<Loader/>}><NotFound /></Suspense>} />
            <Route path="/" element={<Suspense fallback={<Loader/>}><AuthLayout /></Suspense>}>
              <Route index element={<Suspense fallback={<Loader/>}><LoginLayout /></Suspense>} />
              <Route path="registration" element={<Suspense fallback={<Loader/>}><Registration /></Suspense>} />
              <Route path="verify" element={<Suspense fallback={<Loader/>}><Verify /></Suspense>} />
              <Route path="forgotPassword" element={<Suspense fallback={<Loader/>}><ForgotPassword /></Suspense>} />
            </Route>
        </Routes>
    </div>
  )
}

export default App