import { Spin } from 'antd'
import React from 'react'

function Loader() {
  return (
    <div className='w-full h-screen flex justify-center items-center fixed top-0 left-0 z-50'>
      <Spin size='large'/>
    </div>
  )
}

export default Loader