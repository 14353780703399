import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { message, notification } from "antd";
import { axiosBaseRequest, axiosBaseUrl } from "../../utils/axiosBaseUrl";
import { saveToken } from "../../utils/Token";

const initialState = {
  userInfo: {},
  loading: false,
  modalContent: {
    visible: false,
    content: "",
  },
  status: "",
  error: "",
};

export const getUserInfo = createAsyncThunk(
  "users/getUserInfo",
  async (_, { rejectWithValue }) => {
    let uri = `users/me`;
    try {
      const { data } = await axiosBaseRequest.get(uri);
      return data;
    } catch (error) {
      message.error(error.message);
      return rejectWithValue(error.message);
    }
  }
);

export const restorePassword = createAsyncThunk(
  "users/restorePassword",
  async (body, { rejectWithValue }) => {
    try {
      let uri = `users/reset-password`;

      const { data } = await axiosBaseRequest.patch(uri, body);
      return data;
    } catch (error) {
      message.error(error.response.data.detail);
      return rejectWithValue(error.message);
    }
  }
);

export const ownRefreshToken = createAsyncThunk(
  "refreshToken",
  async (body, { rejectWithValue, dispatch }) => {
    try {
      let uri = `token_refresh`;

      const { data } = await axiosBaseUrl.post(uri, body);
      saveToken(data.access_token, data.refresh_token);
      return data;
    } catch (error) {
      message.error(error.response.data.detail);
      return rejectWithValue(error.message);
    }
  }
);

export const changeUserInfo = createAsyncThunk(
  "users/changeUserInfo",
  async (body, { rejectWithValue, dispatch }) => {
    try {
      let uri = `users/`;

      const { data } = await axiosBaseRequest.patch(uri, body);
      dispatch(
        ownRefreshToken({
          refresh_token: localStorage.getItem("refresh_token"),
        })
      );
      dispatch(getUserInfo());
      return data;
    } catch (error) {
      message.error(error.response.data.detail);
      return rejectWithValue(error.message);
    }
  }
);

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setModalVisible: (state, action) => {
      state.modalContent = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getUserInfo.pending, (state, action) => {
      state.status = "pending";
    });
    builder.addCase(getUserInfo.fulfilled, (state, action) => {
      state.userInfo = action.payload;
    });
    builder.addCase(getUserInfo.rejected, (state, action) => {
      state.status = "rejected";
      state.error = action.payload;
    });

    builder.addCase(restorePassword.pending, (state, action) => {
      state.status = "pending";
      state.loading = true;
    });
    builder.addCase(restorePassword.fulfilled, (state, action) => {
      state.status = "Success";
      notification["success"]({
        description: "Пароль изменен!",
      });
      state.loading = false;
      state.modalVisible = false;
    });
    builder.addCase(restorePassword.rejected, (state, action) => {
      state.status = "rejected";
      state.error = action.payload;
      state.loading = false;
    });

    builder.addCase(changeUserInfo.pending, (state, action) => {
      state.status = "pending";
      state.loading = true;
    });
    builder.addCase(changeUserInfo.fulfilled, (state, action) => {
      state.status = "Success";
      notification["success"]({
        description: "Личные данные изменены!",
      });
      state.loading = false;
      state.modalVisible = false;
    });
    builder.addCase(changeUserInfo.rejected, (state, action) => {
      state.status = "rejected";
      state.error = action.payload;
      state.loading = false;
    });
  },
});

export const { setModalVisible } = userSlice.actions;

export default userSlice.reducer;
